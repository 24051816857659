@import url("https://fonts.googleapis.com/css2?family=poppins:wght@200;300;400;600;700&display=swap");
:root {
  --green: #16a085;
  --black: #444;
  --light-color: #777;
  --box-shadow: 0.5rem 0.5rem 0 rgba(22, 160, 133, 0.2);
  --text-shadow: 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.2);
  --border: 0.2rem solid var(--green);
}
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-transform: capitalize;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 7rem;
  scroll-behavior: smooth;
}

section {
  padding: 2rem 9%;
}

section:nth-child(even) {
  background: #f5f5f5;
}
#charts {
  position: relative;
  z-index: 1000000000000;
}

.heading {
  text-align: center;
  padding-bottom: 2rem;
  text-shadow: var(--text-shadow);
  text-transform: uppercase;
  color: var(--black);
  font-size: 5rem;
  letter-spacing: 0.4rem;
}

.heading span {
  text-transform: uppercase;
  color: var(--green);
}

.btn {
  display: inline-block;
  margin-top: 1rem;
  padding: 1.5rem;
  padding-left: 1rem;
  border: var(--border);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  color: var(--green);
  cursor: pointer;
  font-size: 1.7rem;
  background: #fff;
}

.btn span {
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  background: var(--green);
  color: #fff;
  margin-left: 0.5rem;
}

.btn:hover {
  background: var(--green);
  color: #fff;
}

.btn:hover span {
  color: var(--green);
  background: #fff;
  margin-left: 1.5rem;
}

/* ############################################################# */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 2rem 9%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
header .logo {
  font-size: 2.5rem;
  color: var(--black);
  cursor: pointer;
}
header .logo i {
  color: var(--green);
}
header .navbar a {
  font-size: 1.7rem;
  margin-left: 2rem;
  color: var(--light-color);
}
header .navbar a:hover {
  color: var(--green);
}

#menu-bar {
  font-size: 2.5rem;
  cursor: pointer;
  color: var(--green);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: none;
  background: #eee;
}
/* ============---------home-------========= */
.home {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-top: 10rem;
}
.home .image {
  flex: 1 1 45rem;
}
.home .image img {
  width: 100%;
}

.home .content {
  flex: 1 1 45rem;
}
.home .content h3 {
  font-size: 4.5rem;
  color: var(--black);
  line-height: 1.8;
  text-shadow: var(--text-shadow);
}
.home .content p {
  font-size: 1.7rem;
  color: var(--light-color);
  line-height: 1.8;
  padding: 1rem 0;
}
/* ============---------icons-------========= */
.icons-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.icons-container .icons {
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  text-align: center;
  padding: 2.5rem;
}
.icons-container .icons i {
  font-size: 4.5rem;
  color: var(--green);
  padding-bottom: 0.7rem;
}

.icons-container .icons h3 {
  font-size: 4.5rem;
  color: var(--black);
  padding: 1rem;
  text-shadow: var(--text-shadow);
}

.icons-container .icons p {
  font-size: 1.7rem;
  color: var(--light-color);
  padding: 1rem;
}
/* ============---------services-------========= */
/* .services{
   
} */
.box_allContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}
.services .box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  width: 49%;
}

.services .box-container .box {
  width: 95%;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  border: var(--border);
  padding: 1.5rem 2rem;
  margin-bottom: 25px;
}

.services .box-container .box i {
  color: var(--green);
  font-size: 5rem;
  padding-bottom: 0.5rem;
}

.services .box-container .box h3 {
  color: var(--black);
  font-size: 2.5rem;
  padding: 1rem 0;
}

.services .box-container .box p {
  color: var(--light-color);
  font-size: 1.4rem;
  line-height: 2;
}
.services .box-container .box p svg {
  color: var(--green);
  font-size: 1.4rem;
  margin-right: 7px;
}
/* ============---------about-------========= */
.about .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.about .row .image {
  flex: 1 1 45rem;
}

.about .row .image img {
  width: 100%;
}

.about .row .content {
  flex: 1 1 45rem;
}

.about .row .content h3 {
  color: var(--black);
  text-shadow: var(--text-shadow);
  font-size: 4rem;
  line-height: 1.8;
}

.about .row .content p {
  color: var(--light-color);
  padding: 1rem 0;
  font-size: 1.5rem;
  line-height: 1.8;
}
/* ============---------doctors-------========= */
.doctors .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 2rem;
}

.doctors .box-container .box {
  text-align: center;
  background: #fff;
  border-radius: 0.5rem;
  border: var(--border);
  box-shadow: var(--box-shadow);
  padding: 2rem;
}

.doctors .box-container .box img {
  width: 100%;
  border: var(--border);
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.doctors .box-container .box h3 {
  color: var(--black);
  font-size: 2.5rem;
}

.doctors .box-container .box span {
  color: var(--green);
  font-size: 1.5rem;
}

.doctors .box-container .box .share {
  padding-top: 2rem;
}

.doctors .box-container .box .share a {
  height: 5rem;
  width: 5rem;
  line-height: 4.5rem;
  font-size: 2rem;
  color: var(--green);
  border-radius: 0.5rem;
  border: var(--border);
  margin: 0.3rem;
}

.doctors .box-container .box .share a:hover {
  background: var(--green);
  color: #fff;
  box-shadow: var(--box-shadow);
}

/* ============---------book-------========= */

.book .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.book .row .image {
  flex: 1 1 45rem;
}

.book .row .image img {
  width: 100%;
}

.book .row form {
  flex: 1 1 45rem;
  background: #fff;
  border: var(--border);
  box-shadow: var(--box-shadow);
  text-align: center;
  padding: 2rem;
  border-radius: 0.5rem;
}

.book .row form h3 {
  color: var(--black);
  padding-bottom: 1rem;
  font-size: 3rem;
}

.book .row form .box {
  width: 100%;
  margin: 0.7rem 0;
  border-radius: 0.5rem;
  border: var(--border);
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
  padding: 1rem;
}

.book .row form .btn {
  padding: 1rem 4rem;
}

/* ============---------review-------========= */
.review .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  gap: 2rem;
}

.review .box-container .box {
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  padding: 2.5rem;
  background: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.review .box-container .box img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;
  border: 0.5rem solid #fff;
}

.review .box-container .box h3 {
  color: #fff;
  font-size: 2.2rem;
  padding: 0.5rem 0;
}

.review .box-container .box .stars i {
  color: #fff;
  font-size: 1.5rem;
}

.review .box-container .box .text {
  color: var(--light-color);
  line-height: 1.8;
  font-size: 1.6rem;
  padding-top: 4rem;
}

.review .box-container .box::before {
  content: "";
  position: absolute;
  top: -4rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--green);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 25rem;
  width: 120%;
  z-index: -1;
}

/* ============---------blogs-------========= */

.blogs .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2rem;
}

.blogs .box-container .box {
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  padding: 2rem;
}

.blogs .box-container .box .image {
  height: 20rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.blogs .box-container .box .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blogs .box-container .box:hover .image img {
  transform: scale(1.2);
}

.blogs .box-container .box .content {
  padding-top: 1rem;
}

.blogs .box-container .box .content .icon {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blogs .box-container .box .content .icon a {
  font-size: 1.4rem;
  color: var(--light-color);
}

.blogs .box-container .box .content .icon a:hover {
  color: var(--green);
}

.blogs .box-container .box .content .icon a i {
  padding-right: 0.5rem;
  color: var(--green);
}

.blogs .box-container .box .content h3 {
  color: var(--black);
  font-size: 3rem;
}

.blogs .box-container .box .content p {
  color: var(--light-color);
  font-size: 1.5rem;
  line-height: 1.8;
  padding: 1rem 0;
}
/* ============---------ContactUs-------========= */
.ContactUs form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 50px auto;
  border: 2px solid var(--green);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ContactUs div {
  width: 100%;
}
.ContactUs .doubleInput {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ContactUs .singleInput {
  margin-bottom: 20px;
}
.ContactUs .doubleInput input {
  width: 45%;
}
.ContactUs .singleInput input,.ContactUs .singleInput textarea {
  width: 100%;
}

.ContactUs .doubleInput input,
.ContactUs .singleInput textarea,
.ContactUs .singleInput input {
  border-radius: 5px;
  border: var(--green) 2px solid;
  padding: 12px;
}
.ContactUs button {
  padding: 10px 30px;
  border: 2px solid var(--green);
  background-color: var(--green);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
/* ============---------footer-------========= */
.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 2rem;
}

.footer .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  padding: 1rem 0;
}

.footer .box-container .box a {
  display: block;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box a i {
  padding-right: 0.5rem;
  color: var(--green);
}

.footer .box-container .box a:hover i {
  padding-right: 2rem;
}

.footer .credit {
  padding: 1rem;
  padding-top: 2rem;
  margin-top: 2rem;
  text-align: center;
  font-size: 2rem;
  color: var(--light-color);
  border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.footer .credit span {
  color: var(--green);
}

.poster {
  width: 85%;
  margin: auto;
}
.poster img {
  width: 100%;
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }

  .header {
    padding: 2rem;
  }

  section {
    padding: 2rem;
  }
  .services .box-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #menu-bar {
    display: initial;
  }

  header .navbar {
    position: absolute;
    top: 115%;
    right: 2rem;
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);
    width: 30rem;
    border: var(--border);
    background: #fff;
    transform: scale(0);
    opacity: 0;
    transform-origin: top right;
    transition: 0.2s ease-out;
  }

  header .navbar.active {
    transform: scale(1);
    opacity: 1;
    transition: 0.2s ease-out;
  }

  header .navbar a {
    font-size: 2rem;
    display: block;
    margin: 2.5rem;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}
